import axios from '@/axios'
import qs from 'qs'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    stock: [],
    totalItems: 0
  },
  mutations: {
    SET_STOCK (state, stockData) {
      state.stock = stockData['hydra:member']
      state.totalItems = stockData['hydra:totalItems']
    },
    ADD_STOCK (state, stock) {
      state.stock.push(stock)
      state.totalItems++
    },
    UPDATE_STOCK (state, stock) {
      state.stock.filter(data => data['@id'].includes(stock['@id']))[0] = stock
    },
    REMOVE_STOCK (state, id) {
      state.stock = state.stock.filter(data => !data['@id'].includes(id))
      state.totalItems--
    }
  },
  actions: {
    fetchStocks ({ commit, rootGetters }, payload) {
      if (rootGetters.hasCancelToken('fetchStock')) {
        rootGetters.cancelToken('fetchStock').cancel()
      }

      commit('SET_CANCEL_TOKEN', 'fetchStock', { root: true })

      return new Promise((resolve, reject) => {
        let url = '/stock/stocks'
        if (payload) {
          url += `?${qs.stringify(payload)}&order[createdAt]=desc`
        }
        axios
          .get(url, {
            cancelToken: rootGetters.cancelToken('fetchStock').token
          })
          .then(response => {
            commit('SET_STOCK', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchStock (_, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`/stock/stocks/${payload.id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addNewStock ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/stock/stocks', payload)
          .then(response => {
            commit('ADD_STOCK', response.data)
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    editStock ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`/stock/stocks/${payload.id}`, payload)
          .then(response => {
            commit('UPDATE_STOCK', response.data)
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    deleteStock ({commit}, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`/stock/stocks/${id}`)
          .then(response => {
            commit('REMOVE_STOCK', id)
            resolve(response)
          })
          .catch(err => reject(err))
      })
    },
    addStockEvents (_, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/stock_event/stock_events', payload)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
      })
    }
  }
}
