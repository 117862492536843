<template>
  <div class="flex items-center">
    <div :class="!fullImg? 'content-image':'content-image-full'">
      <a target="_blank" :href="imgSrc">
        <img v-if="imgSrc !== null" :src="imgSrc">
      </a>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'StockImage',
  props: {
    data: {
      type: [String],
      required: false
    },
    fullImg: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    bearer () {
      return this.$store.getters['auth/accessToken']
    },
    imgSrc () {
      if (!this.data) {
        return null
      }
      return `${this.data}?bearer=${this.bearer}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .content-image {
    width: 100px;
    height: 100px;
    position: relative;
    img {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
    &-full {
      max-width: 200px;
      img {
        width: 100%;
      }
    }
  }
</style>