import moduleStock from './moduleStock'

export default {
  created () {
    if (!moduleStock.isRegistered) {
      this.$store.registerModule('stock', moduleStock)
      moduleStock.isRegistered = true
    }
  }
}
